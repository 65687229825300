import {
    Simulator,
    TablesOWner,
    SalesTable,
    GetLinks,
    CommissionTax,
    OwnerClient,
    ConsultCpf,
    GenerateLink,
    CreateProposal,
    ConsultLocalize,
    Proposals,
    SolicitarPIX,
    commissionTaxUpdate,
    ProposalBI,
    ConsultLocalizeWithoutBirthday,
    ReportProposalError
} from "service/online/proposals"

import Contract from "./online/contract";
import {
    GetCep
} from "./external";

import {
    Available,
    ProposalsComnisssions,
    getAccount,
    RequestCommission,
    ListRequestCommission,
    PixAwardConsult,
    PixAward,
    CommissionlBI,
    ResendRequestCommission,
    GetAccountPIX,
    SaveAccountPIX,
    UpdateAccountForBanner
} from "service/online/commissions"

import {
    List,
    SalesCounter,
    SalesCounterUpdate,
    SalesTableAdmin,
    newFilial,
    GenerateToken,
    ConsultToken,
    FilialAutoRegister,
    GetUpdate,
    RefuseUpdate,
    Update as UpdateBranch,
    UpdateNew
} from "service/online/filiais"

import {
    StatisticsAdmin,
    StatisticsPopulate
} from "service/data-api/statistics"

import {
    ListIndicators
} from "service/online/indicators"

import {
    Me,
    Login,
    Logout,
    Update,
    MeSale,
    RedefinePassword,
    MeGestor
} from "service/online/auth"

import {
    ConsultAccount,
    ValidateAccount
} from "service/online/transfeera"

import {
    getKeys,
    editKeys,
    getMyPosts,
    getOptionsPost,
    createUser,
    getLeads,
    getLoginLink
} from "service/online/marketing"

import {
    getGestor,
    newGestor,
    updateGestor,
    deleteGestor
} from "service/online/gestor"

export const RemoteServices = {
    auth: {
        Me,
        Login,
        Logout,
        Update,
        MeSale,
        RedefinePassword,
        MeGestor
    },
    proposals: {
        Simulator,
        TablesOWner,
        SalesTable,
        GetLinks,
        CommissionTax,
        OwnerClient,
        ConsultCpf,
        GenerateLink,
        CreateProposal,
        ConsultLocalize,
        Proposals,
        SolicitarPIX,
        commissionTaxUpdate,
        ProposalBI,
        ConsultLocalizeWithoutBirthday,
        ReportProposalError
    },
    Contract,
    external: {
        GetCep
    },
    commissions: {
        Available,
        ProposalsComnisssions,
        getAccount,
        RequestCommission,
        ListRequestCommission,
        PixAwardConsult,
        PixAward,
        CommissionlBI,
        ResendRequestCommission,
        GetAccountPIX,
        SaveAccountPIX,
        UpdateAccountForBanner
    },
    filiais: {
        List,
        SalesCounter,
        SalesCounterUpdate,
        SalesTableAdmin,
        newFilial,
        GenerateToken,
        ConsultToken,
        FilialAutoRegister,
        GetUpdate,
        RefuseUpdate,
        UpdateBranch,
        UpdateNew
    },
    statistics: {
        StatisticsAdmin,
        StatisticsPopulate
    },
    indicators: {
        ListIndicators
    },
    transfeera: {
        ConsultAccount,
        ValidateAccount
    },
    marketing: {
        getKeys,
        editKeys,
        getMyPosts,
        getOptionsPost,
        createUser,
        getLeads,
        getLoginLink
    },
    gestor: {
        getGestor,
        newGestor,
        updateGestor,
        deleteGestor
    }
}

export default RemoteServices;
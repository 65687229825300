import { Remote } from "service/remote";

export const ReportProposalError = async (body: any) => {
    return await Remote({
        method: "post",
        urn: `online/proposals/report-seller-error`,
        data: body
    })

}

export default ReportProposalError;